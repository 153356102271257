import { EaseChatClient } from '@/IM/initwebsdk'
import dateFormat from '@/utils/dateFormater'
import {requestHttpGet} from '@/api/main'
import storage from 'store2'
import { forEach } from 'lodash'

//会话体
let iobj = {
  id:"",
  nickname:"",
  avatarurl:"",
  msg:"",
  time:"",
  unread_num:0,
  order:0
}
const ConversationUtils = {
  getMessageType(type,msg){
    if(type == 'audio'){
       return '【语音】'
    }
    if(type == 'txt'){
      return msg
    }
    if(type == 'img'){
      return '【图片】'
    }
    if(type == 'file'){
      return '【文件】'
    }
    if(type == 'video'){
      return '【视频】'
    }
    if(type == 'custom'){
      return '【订单】'
    }
  },
  splitArray(array, size) {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  },
  //获取会话信息储存到本地 -- 用户
  async getMessageSaveLocalList(vuedom){
    let isNotMessage = true
    let cursor = ''
    let datas = []
    let userArray = []
    let items = []
    while (isNotMessage) {
      let resd = await EaseChatClient.getServerConversations({pageSize:50,cursor:cursor})
      cursor = resd.data.cursor
      if(resd.data.cursor == ''){
        isNotMessage = false
      }
      resd.data.conversations.forEach(e => {
        if(e.conversationType == 'singleChat'){
          datas.push(e)
          userArray.push(e.conversationId)
        }
      });
    }
    
  
    if(userArray.length>100){
      let res = this.splitArray(userArray,100)
      //赋值用户信息
       //赋值用户信息
       for(let index = 0; index < res.length; index++) {
        const e = res[index];
        let userinfo = await EaseChatClient.fetchUserInfoById(e)
        Object.keys(userinfo.data).forEach(item => {
          let iobj = {}
          iobj.id = item
          iobj.nickname = userinfo.data[item]['nickname']
          iobj.avatarurl = userinfo.data[item]['avatarurl']
          datas.forEach(e => {
            if(e.conversationId == item){
              iobj.chatType = e.lastMessage.chatType == "groupchat" ? "groupChat" : 'singleChat'
              iobj.msg = this.getMessageType(e.lastMessage.type,e.lastMessage.msg)
              iobj.time = dateFormat('MM/DD HH:mm',e.lastMessage.time)
              iobj.order = e.lastMessage.time
              iobj.unread_num = e.unReadCount
              iobj.id = item
            }
          });
          items.push(iobj)
        })
      }
    }else{
      let userinfo = await EaseChatClient.fetchUserInfoById(userArray)
        Object.keys(userinfo.data).forEach(item => {
          let iobj = {}
          iobj.id = item
          iobj.nickname = userinfo.data[item]['nickname']
          iobj.avatarurl = userinfo.data[item]['avatarurl']
          datas.forEach(e => {
            if(e.conversationId == item){
              iobj.chatType = e.lastMessage.chatType == "groupchat" ? "groupChat" : 'singleChat'
              iobj.msg = this.getMessageType(e.lastMessage.type,e.lastMessage.msg)
              iobj.time = dateFormat('MM/DD HH:mm',e.lastMessage.time)
              iobj.order = e.lastMessage.time
              iobj.unread_num = e.unReadCount
              iobj.id = item
            }
          });
          items.push(iobj)
        })
    }
  
    
    //获取备注
    await this.getUserNote(items)
    //获取免打扰
    await this.getSilentModeForConversations(items,vuedom)
    
    console.log("获取的原始会话消息",items)
    await vuedom.$messageDb.setItem("corlist",items)
  },
  //获取会话信息储存到本地 -- 群组
  async getMessageSaveLocalListForGroup(){
    let isNotMessage = true
    let cursor = ''
    let datas = []
    while (isNotMessage) {
      let resd = await EaseChatClient.getServerConversations({pageSize:50,cursor:cursor})
      cursor = resd.data.cursor
      if(resd.data.cursor == ''){
        isNotMessage = false
      }
      resd.data.conversations.forEach(e => {
        if(e.conversationType != 'singleChat'){
          datas.push(e)
        }
      });
    }
    return datas
  },
  //获取会话信息储存到本地
  async getGroupSaveLocalList(vuedom){

    let isNotMessage = true
    let cursor = 0
    let items = []
    while (isNotMessage) {
      let resd = await EaseChatClient.getJoinedGroups({pageNum: cursor,pageSize: 20,needAffiliations:true,needRole:true})
      console.log(resd)
      cursor++
      if(resd.entities.length<= 0){
        isNotMessage = false
      }
      resd.entities.forEach(e => {
        items.push({
          chatType:"groupChat",
          id:e.groupId,
          groupname:e.groupName,
          role:e.role,
          disabled:e.disabled,
          affiliationsCount:e.affiliationsCount,
          order:0
        })
      });
    }
    
    let corDatas = await this.getMessageSaveLocalListForGroup()
    console.log(corDatas)
    corDatas.forEach(item => {
       items.forEach(ig => {
          if(ig.id == item.conversationId){
            ig.unread_num = item.unReadCount
            ig.order = item.lastMessage.time
            ig.msg = this.getMessageType(item.lastMessage.type,item.lastMessage.msg)
            ig.time = dateFormat('MM/DD HH:mm',item.lastMessage.time)
          }
      })
    });
    console.log(items)
    await this.getGroupNote(items)
    //获取免打扰
    await this.getSilentModeForConversations(items,vuedom)
    await vuedom.$messageDb.setItem("grouplist",items)
  },
  //获取群组信息保存到本地
  async setGroupToLocal(id,listItem,vuedom,pageid){

    let data = await EaseChatClient.getGroupInfo({groupId:id})
    let groupInfo = data.data[0]
    console.log(groupInfo)
    let res = await vuedom.$messageDb.getItem("grouplist")
    res.push({
      chatType:"groupChat",
      id:groupInfo.id,
      groupname:groupInfo.name,
      role:storage.get('ImAcount') == groupInfo.owner?'owner':'',
      disabled:groupInfo.disabled,
      affiliationsCount:groupInfo.affiliations_count,
      order:new Date().getTime(),
      unread_num:0,
      msg:'您已成功加入群组',
      time:''
    })
    await vuedom.$messageDb.setItem("grouplist",res)
    if(pageid == 2){
      listItem.push({
        chatType:"groupChat",
        id:groupInfo.id,
        groupname:groupInfo.name,
        role:storage.get('ImAcount') == groupInfo.owner?'owner':'',
        disabled:groupInfo.disabled,
        affiliationsCount:groupInfo.affiliations_count,
        order:new Date().getTime(),
        unread_num:0,
        msg:'您已成功加入群组',
        time:''
      })
      this.updateTopList(listItem)
      listItem.sort((a,b)=>{ return b.order - a.order})
    }
  },
  //获取本地会话
  async getlocalCor(vuedom){
    let res  = await vuedom.$messageDb.getItem("corlist")
   
    this.updateTopList(res)
    console.log("本地会话原始信息：",res)
    res.sort((a,b)=>{ return b.order - a.order})

    return res
  },
  //获取本地群组
  async getlocalGroup(vuedom){
    let res  = await vuedom.$messageDb.getItem("grouplist")
    this.updateTopList(res)
    res.sort((a,b)=>{ return b.order - a.order})
    console.log(res)
    return res
  },


  //批量获取会话免打扰信息
  async getSilentModeForConversations(listItem,vm){
   
    if(listItem.length <= 0){
      return 
    }
    let conversationList = []
    listItem.forEach(item => {
      item.mdtype = ''
      let obj = {}
      obj.id = item.id
      obj.type = item.chatType == "groupChat" ? 'groupChat' : 'singleChat'
      conversationList.push(obj)
    });
    let resArr = []
    let groupArr = []
    if(conversationList.length>20){
      let res = this.splitArray(conversationList,20)
      for(let index = 0; index < res.length; index++) {
        const e = res[index];
        let resdata = await EaseChatClient.getSilentModeForConversations({conversationList:e})
        resArr = Object.assign({}, resArr, resdata.data.user)
        groupArr = Object.assign({}, groupArr, resdata.data.group)
      }
    }else{
        let resdata = await EaseChatClient.getSilentModeForConversations({conversationList:conversationList})
        resArr = Object.assign({}, resArr, resdata.data.user)
        groupArr = Object.assign({}, groupArr, resdata.data.group)
    }
    listItem.forEach(item => {
      if(item.chatType == 'groupChat'){
        item.mdtype = groupArr[item.id]?groupArr[item.id]['type']:""
      }else{
        item.mdtype = resArr[item.id]?resArr[item.id]['type']:""
      }
    });
    console.log("增加免打扰配置:",listItem)

  },
  updateTopListForDelete(listItem,id){
    listItem.forEach(item => {
      if(item.id == id){
        item.order = item.order/10
      }
    });
    listItem.sort((a,b)=>{ return b.order - a.order})
  },
  updateTopListForSave(listItem,id){
    listItem.forEach(item => {
      if(item.id == id){
        item.order = item.order*10
      }
    });
    listItem.sort((a,b)=>{ return b.order - a.order})
  },
  async updateTopList(listItem){
    console.log("更改置顶")
    let userTopIds = storage.has("chatTop")?storage.get("chatTop").split(","):[]
    let groupTopIds = storage.has("chatGroupTop")?storage.get("chatGroupTop").split(","):[]
    if(listItem.length <= 0){
      return 
    }
    console.log(userTopIds)
    //处理单聊置顶
    listItem.forEach((item,i) => {
      if(item.chatType != "groupChat"){
        userTopIds.forEach(e => {
            if(item.id == e){
              var s = item.order+''
              if(s.length<=13){
                item.order = item.order*10
              }
            }
        });
      }
    });
    console.log(listItem)
    //处理群聊置顶
    listItem.forEach((item,i) => {
      if(item.chatType == "groupChat"){
        groupTopIds.forEach(e => {
            if(item.id == e){
              var s = item.order+''
              if(s.length<=13){
                item.order = item.order*10
              }
            }
        });
      }
    });
    console.log(listItem)
  },
  //获取好友
  async getContactsList() {
    let listItem = []
     //获取好友列表
     let res = await EaseChatClient.getContacts()
     let userinfo = await EaseChatClient.fetchUserInfoById(res.data)
     Object.keys(userinfo.data).forEach( item => {
       listItem.push({
         chatType:"fildes",
         id:item,
         nickname:userinfo.data[item]['nickname'],
         avatarurl:userinfo.data[item]['avatarurl']
       })
     });
     return listItem
  },
  //获取群组
  async getJoinedGroupsByuser(pageNum,pageSize) {
    let listItem = []
    //获取群组列表
    let res = await EaseChatClient.getJoinedGroups({pageNum: pageNum,pageSize: pageSize})
    res.entities.forEach(item => {
      listItem.push({
        chatType:"groupChat",
        id:item.groupId,
        groupname:item.groupName,
        disabled:item.disabled,
      })
    });
  },
  //获取群组
  async getJoinedGroups(pageNum,pageSize) {
    let listItem = []
    //获取群组列表
    let res = await EaseChatClient.getJoinedGroups({pageNum: pageNum,pageSize: pageSize,needAffiliations:true,needRole:true})
    res.entities.forEach(item => {
      listItem.push({
        chatType:"groupChat",
        id:item.groupId,
        groupname:item.groupName,
        role:item.role,
        disabled:item.disabled,
        affiliationsCount:item.affiliationsCount
      })
    });

    let resd = await EaseChatClient.getServerConversations({pageSize:100,cursor:""})
    console.log("会话信息：",resd)
    resd.data.conversations.forEach(item => {
      
      if(item.lastMessage && item.lastMessage.chatType == "groupchat"){
        console.log("群组会话列表",listItem)
        console.log("会话列表",item)
        listItem.forEach(ig => {
          if(ig.id == item.lastMessage.to){
            ig.unread_num = item.unread_num
            ig.order = item.lastMessage.time
            ig.msg = item.lastMessage.msg
            ig.time = dateFormat('MM/DD HH:mm',item.lastMessage.time)
          }
        })
      }
    });

    // console.log(listItem)
    this.getGroupImage(listItem)
    await this.getGroupNote(listItem)
    return listItem
  },
  async getUserNote(listItem){
    let groupNoteInfo = await requestHttpGet('https://www.binliyoujia.com/index.php?ctl=Goods_Imhx&met=usergroup_search&typ=json', {user_id:storage.get("ImAcount")})
    console.log(groupNoteInfo)
    if(groupNoteInfo.status == 200){
      for (let index = 0; index < listItem.length; index++) {
        let item = listItem[index];
        Object.keys(groupNoteInfo.data).forEach( ind => {
          if(item.id == groupNoteInfo.data[ind].touser_id){
                item.nickname  =  groupNoteInfo.data[ind].comment_name?groupNoteInfo.data[ind].comment_name:item.nickname
          }
          storage.set('header-name-bz'+item.id,item.nickname)
        });
      }
    }
  },
  async getGroupNote(listItem){
    let groupNoteInfo = await requestHttpGet('https://www.binliyoujia.com/index.php?ctl=Goods_Imhx&met=usergroup_search&typ=json', {user_id:storage.get("ImAcount")})
    if(groupNoteInfo.status == 200){
      for (let index = 0; index < listItem.length; index++) {
        let item = listItem[index];
        Object.keys(groupNoteInfo.data).forEach( ind => {
          if(item.id == groupNoteInfo.data[ind].group_id){
                item.groupname  =  groupNoteInfo.data[ind].comment_name?groupNoteInfo.data[ind].comment_name:item.groupname
          }
          storage.set('header-name-bz'+item.id,item.groupname)
        });
      }
    }
  },
  async getGroupImage(listItem){
    // listItem.forEach(e => {
    //   e.avatarurl = '../assets/groupTheme.png'
    //   const url = new URL('./src', import.meta.url)
    // });
    //获取群组成员
    // for (let index = 0; index < listItem.length; index++) {
    //   let item = listItem[index];
    //   let data = await EaseChatClient.getGroupInfo({groupId:item.id})
    //   let groupInfo = data.data[0]
    //   let arruser = []
    //   let userheader = []
    //   for (let j = 0; j < groupInfo.affiliations.length; j++) {
    //     let jitem = groupInfo.affiliations[j];
    //     if(jitem.member){
    //       arruser.push(jitem.member)
    //     }else if(jitem.owner){
    //       arruser.push(jitem.owner)
    //     }
    //   }
    //   let userinfo = await EaseChatClient.fetchUserInfoById(arruser)
    //   Object.keys(userinfo.data).forEach(m => {
    //     if(userinfo.data[m]['avatarurl']){
    //       userheader.push(userinfo.data[m]['avatarurl'])
    //     }
    //   });
    //   item.avatarurls = userheader
    //   console.log("群组头像",userheader)
    // }
  },
  async getGroupImageFor(groupInfo){
    //获取群组成员
      let arruser = []
      let userheader = []
      for (let j = 0; j < groupInfo.affiliations.length; j++) {
        let jitem = groupInfo.affiliations[j];
        if(jitem.member){
          arruser.push(jitem.member)
        }else if(jitem.owner){
          arruser.push(jitem.owner)
        }
      }
      let userinfo = await EaseChatClient.fetchUserInfoById(arruser)
      Object.keys(userinfo.data).forEach(m => {
        if(userinfo.data[m]['avatarurl']){
          userheader.push(userinfo.data[m]['avatarurl'])
        }
      });
      return userheader
  },
  //获取会话
  async getConversationlist(pageNum,pageSize) {
    let userArray = []
    let infochal = []
    let listItem = []
    let res = await EaseChatClient.getServerConversations({pageSize:pageSize,cursor:pageNum})
    console.log("会话信息：",res)
    res.data.conversations.forEach(item => {
      //过滤会话 群组不显示
      console.log("会话信息：",item)
      if(item.lastMessage && item.lastMessage.chatType != "groupchat"){
        //获取会话人员信息 如果最后一条消息的from是我自己 对方是 to  如果from不是自己 对方是 from
        if(item.lastMessage.from == storage.get('ImAcount')){
          if(item.lastMessage.to != ''){
            userArray.push(item.lastMessage.to)
            infochal.push(item)
          }
        }else{
          if(item.lastMessage.from != ''){
            userArray.push(item.lastMessage.from)
            infochal.push(item)
          }
        }
      }
    });
    // console.log(userArray)
    //赋值用户信息
    if(userArray.length>100){
      let res = this.splitArray(userArray,100)
      //赋值用户信息
       //赋值用户信息
       for(let index = 0; index < res.length; index++) {
        const e = res[index];
        let userinfo = await EaseChatClient.fetchUserInfoById(e)
  
        Object.keys(userinfo.data).forEach(item => {
          let iobj = {}
          iobj.id = item
          iobj.nickname = userinfo.data[item]['nickname']
          iobj.avatarurl = userinfo.data[item]['avatarurl']
          infochal.forEach(ie => {
            if(ie.lastMessage.to == item || ie.lastMessage.from == item){

              iobj.chatType = ie.lastMessage.chatType == "groupchat" ? "groupChat" : 'singleChat'
              iobj.msg = ie.lastMessage.msg
              iobj.time = dateFormat('MM/DD HH:mm',ie.lastMessage.time)
              iobj.order = ie.lastMessage.time
              iobj.unread_num = ie.unread_num
              iobj.ids = res.data.cursor
            }
          });
          listItem.push(iobj)
        });
      }
    }else{
      let userinfo = await EaseChatClient.fetchUserInfoById(userArray)
 
      Object.keys(userinfo.data).forEach(item => {
        let iobj = {}
        iobj.id = item
        iobj.nickname = userinfo.data[item]['nickname']
        iobj.avatarurl = userinfo.data[item]['avatarurl']
        infochal.forEach(ie => {
          if(ie.lastMessage.to == item || ie.lastMessage.from == item){

            iobj.chatType = ie.lastMessage.chatType == "groupchat" ? "groupChat" : 'singleChat'
            iobj.msg = ie.lastMessage.msg
            iobj.time = dateFormat('MM/DD HH:mm',ie.lastMessage.time)
            iobj.order = ie.lastMessage.time
            iobj.unread_num = ie.unread_num
            iobj.ids = res.data.cursor
          }
        });
        listItem.push(iobj)
      });
    }
    
    // console.log(listItem)
    
    listItem.sort((a,b)=>{ return b.order - a.order})
    await this.getUserNote(listItem)
    
    return listItem
  }
}

export {
  ConversationUtils
}
