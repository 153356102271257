import Vue from 'vue'
import antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less';
import App from './App'
import router from './router'
import { VueAxios } from './utils/request'
import { ipc } from '@/utils/ipcRenderer'
import localforage from 'localforage'


// 使用antd
Vue.use(antd)
// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios)

Vue.use(localforage)
const messageDb = localforage.createInstance({name:'messageDb'})
// 全局注入IPC通信
Vue.prototype.$ipc = ipc
Vue.prototype.$messageDb = messageDb
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  beforeCreate(){
      Vue.prototype.$bus = this;
  }
}).$mount('#app')
